@font-face {
    font-family: 'Rubik';
    src: url('../public/fonts/Rubik-Regular.ttf') format('truetype'),
    url('../public/fonts/Rubik-Bold.ttf') format('truetype'),
    url('../public/fonts/Rubik-SemiBold.ttf') format('truetype'),
    url('../public/fonts/Rubik-Italic.ttf') format('truetype'),
    url('../public/fonts/Rubik-MediumItalic.ttf') format('truetype'),
    url('../public/fonts/Rubik-SemiBoldItalic.ttf') format('truetype'),
    url('../public/fonts/Rubik-Medium.ttf') format('truetype')
    ;
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Josefin Sans';
    src: url('../public/fonts/JosefinSans-Regular.ttf') format('truetype'),
    url('../public/fonts/JosefinSans-Bold.ttf') format('truetype'),
    url('../public/fonts/JosefinSans-SemiBold.ttf') format('truetype'),
    url('../public/fonts/JosefinSans-Medium.ttf') format('truetype')
    ;
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }